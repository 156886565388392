import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { IMenuItens } from '../Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { HomeOutlined } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const Breadcrumb = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [pageStructure, setPageStructure] = useState<IMenuItens[]>([]);

  useEffect(() => {
    const structure = JSON.parse(localStorage.getItem('menusDinamicos') ?? '[]') as IMenuItens[];
    setPageStructure(structure);
  }, []);

  const findBreadcrumb = (path: string, structure: IMenuItens[]): any[] | null => {
    for (const page of structure) {
      if (page.link === path) {
        return [page];
      } else if (page.filhos && page.filhos.length > 0) {
        const childBreadcrumb = findBreadcrumb(path, page.filhos);
        if (childBreadcrumb) {
          return [page, ...childBreadcrumb];
        }
      }
    }
    return null;
  };

  const breadcrumb = findBreadcrumb(currentPath, pageStructure);

  return (
    <NavBreadcrumb className="breadcrumb">
      <HomeOutlined style={{ marginRight: '8px', height: '16px', width: '16px' }} />
      <NavigateNextRoundedIcon style={{ height: '16px', width: '16px', marginRight: '8px' }} />
      {breadcrumb &&
        breadcrumb.map((item, index) => (
          <span key={item.link} style={{ display: 'flex', alignItems: 'center' }}>
            {index > 0 && (
              <NavigateNextRoundedIcon
                style={{ height: '16px', width: '16px', marginRight: '8px' }}
              />
            )}
            <LinkBreadcrumb href={item.link}>{item.nome}</LinkBreadcrumb>
          </span>
        ))}
    </NavBreadcrumb>
  );
};

const NavBreadcrumb = styled.nav`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--gray-medium);
  display: flex;
  align-items: center;
`;

const LinkBreadcrumb = styled.a`
  color: var(--gray-medium);
  margin-right: 8px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default Breadcrumb;
