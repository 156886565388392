import {
  IComandaEtapaReunioes,
  IEtapa,
  IReuniao,
  StatusReuniaoEnum,
} from '../../../commons/types/crm';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import ProgramaPPC from './ppc';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Aprovar from './Aprovar';
import { useReuniaosActions } from '../../../states/reuniao/reuniao.actions';

function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export interface IEtapaComponent {
  etapa: IEtapa;
  reuniao: IComandaEtapaReunioes | undefined;
  index: number;
  expanded: number;
  handleChange: Function;
}

const Etapa: React.FC<IEtapaComponent> = ({ etapa, reuniao, index, expanded, handleChange }) => {
  const [status, setStatus] = useState(reuniao?.status);
  const [aprovar, setAprovar] = useState(
    reuniao?.status === StatusReuniaoEnum.PENDENTE_APROVACAO && !reuniao?.comentarioContato,
  );
  const [open, setOpen] = useState(false);
  const [nota, setNota] = useState(0);
  const [comentario, setComentario] = useState('');
  const [comentarioReprovar, setComentarioReprovar] = useState('');
  const actions = useReuniaosActions();

  const getStatusColor = (status?: StatusReuniaoEnum) => {
    console.log('status', status);
    let color: string = '#b3b3b3';
    if (!status) return color;

    switch (status) {
      case StatusReuniaoEnum.PENDENTE_APROVACAO:
      case StatusReuniaoEnum.APROVADA:
        color = '#ECFDF3';
        break;
      case StatusReuniaoEnum.AGENDADA:
        color = '#33BB49';
        break;
      case StatusReuniaoEnum.CANCELADA:
        color = '#CD0105';
        break;
      case StatusReuniaoEnum.PENDENTE:
      default:
        color = '#FFFAEB';
        break;
    }
    return color;
  };

  const handleApprove = () => {
    actions
      .aprovar(reuniao?.tbEtapaId!, reuniao?.id!, {
        nota,
        comentario,
      })
      .then(() => {
        toast.success('Aprovado!');
        setStatus(StatusReuniaoEnum.APROVADA);
        setAprovar(false);
        setOpen(false);
      })
      .catch((e) => toast.error('Falha ao aprovar, tente novamente ou entre em contato.'));
  };

  const handleReject = () => {
    actions
      .reprovar(reuniao?.tbEtapaId!, reuniao?.id!, {
        comentarioReprovar,
      })
      .then(() => {
        toast.success('Reprovado!');
        setOpen(false);
      })
      .catch((e) => toast.error('Falha ao reprovar, tente novamente ou entre em contato.'));
  };

  const handleClose = () => {
    setNota(0);
    setComentario('');
    setOpen(false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === index}
        onChange={() => handleChange(index)}
        sx={{
          backgroundColor: expanded === index ? '#fff' : 'unset',
          width: '100%',
          margin: '5px',
        }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">{etapa.nome}</Typography>
            <Grid
              sx={{
                backgroundColor: getStatusColor(reuniao?.status),
                padding: '2px 10px',
                borderRadius: '15px',
                width: 'fit-content'
              }}
            >
              {!(reuniao?.status === StatusReuniaoEnum.PENDENTE_APROVACAO && reuniao?.comentarioContato == undefined) && (
                <Typography>
                  {!reuniao?.status ? (
                    'PENDENTE'
                  ) : (
                    <>
                      {reuniao?.status} {new Date(reuniao?.dtRealizadaFim?.toString()!)?.toLocaleDateString()}
                    </>
                  )}
                </Typography>
              )}
              {reuniao?.status === StatusReuniaoEnum.PENDENTE_APROVACAO && reuniao?.comentarioContato == undefined && (
                <Grid
                  sx={{
                    backgroundColor: '#33BB49',
                    padding: '2px 10px',
                    borderRadius: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(true)}
                >
                  APROVAR
                </Grid>
              )}
            </Grid>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {etapa.nome == 'Análise de Perfil e Planejamento de Carreira' ? (
            <ProgramaPPC />
          ) : (
            <Typography>{createHTMLFromString(etapa.texto)}</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {open && (
        <Aprovar
          open={open}
          handleClose={handleClose}
          onApprove={handleApprove}
          onReject={handleReject}
          reuniao={reuniao!}
          etapa={etapa}
          nota={nota}
          setNota={setNota}
          comentario={comentario}
          setComentario={setComentario}
          comentarioReprovar={comentarioReprovar}
          setComentarioReprovar={setComentarioReprovar}
        />
      )}
    </>
  );
};

export default Etapa;
