import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  ThemeProvider,
} from '@mui/material';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridEventListener, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { ButtonStato, GridContainer, TextFieldStato } from '../../../commons/styleds/styleds';
import { ICompetencia, IdDescricao } from '../../../commons/types/programa';
import MultiSelect from '../../../components/Generics/MultiSelect';
import { useCompetenciasActions } from '../../../states/competencia/competencia.actions';
import { IResultadoRealizacaoCompetencia } from '../../../commons/types/analisePerfil';
import { usePerfilCompetenciasActions } from '../../../states/perfilCompetencias/perfilCompetencia.actions';
import { authAtom } from '../../../states/usuarios/AuthState';

interface Props {
  novoUsuario: boolean;
}

const empty: IResultadoRealizacaoCompetencia = {
  id: 0,
  problemaDesafio: '',
  acao: '',
  sintese: '',
  resultado: '',
  idCompetencias: [],
  tbCompetencias: [],
  ativo: true,
};

const PerfilRealizacoes: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;

  const methods = useFormContext();
  const competenciasActions = useCompetenciasActions();
  const ppcCompetenciasActions = usePerfilCompetenciasActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };
  const auth = useRecoilValue(authAtom);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [perfilRealizacoes, setResultadoRealizacaoCompetencias] = useState<
    IResultadoRealizacaoCompetencia[]
  >([]);
  const [perfilRealizacoesTemp, setResultadoRealizacaoCompetenciaTemp] =
    useState<IResultadoRealizacaoCompetencia>();
  const [selectedResultadoRealizacaoCompetencias, setSelectedResultadoRealizacaoCompetencias] =
    useState<IResultadoRealizacaoCompetencia[] | undefined>(undefined);

  const id = methods.watch('id');

  const [competencias, setCompetencias] = useState<ICompetencia[]>([]);
  const [competencia, setCompetencia] = useState<ICompetencia>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
    },
    {
      field: 'problemaDesafio',
      headerName: 'Problema - Desafio',
      width: 200,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 160,
    },
    {
      field: 'resultado',
      headerName: 'Resultados',
      width: 160,
    },
    {
      field: 'sintese',
      headerName: 'Síntese',
      width: 160,
    },
    {
      field: 'tbCompetencias',
      headerName: 'Competências',
      width: 250,
      valueGetter: (params: ICompetencia[]) => {
        const competencias = params;
        const descricoes = competencias.map((comp) => comp.descricao);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 30,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IResultadoRealizacaoCompetencia;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const deleteValue = () => {
    ppcCompetenciasActions
      .delete(perfilRealizacoesTemp?.id!)
      .then((resp: IResultadoRealizacaoCompetencia[]) => {
        const sub = perfilRealizacoes.filter((f) => f.id !== perfilRealizacoesTemp?.id);
        setResultadoRealizacaoCompetencias(sub);
        atualizarFormulario(empty);
      })
      .catch((err: any) => toast.warn(err));

    // methodPerfil.reset(perfilRealizacoesTemp, { keepDefaultValues: true });
    // setIdAtualizar(perfilRealizacoesTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const HandleAtivoInativo = (usuario: any) => {
    // usuario.ativo = usuario.ativo ? false : true;
    // AtualizarUsuario(usuario)
    // atualizarFormulario(empty);
  };

  const Atualizar = (data: any) => {
    ppcCompetenciasActions
      .update(data.id, data)
      .then((result: IResultadoRealizacaoCompetencia) => {
        const sub = perfilRealizacoes.filter((f) => f.id !== result.id);
        setResultadoRealizacaoCompetencias(sub.concat(result));
        atualizarFormulario(empty);
        toast.success('Atualizado com sucesso');
        setLoadingSave(false);
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
        setLoadingSave(false);
      });
  };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.idCompetencias != undefined)
      data.tbCompetencias = competencias.filter((c) => data.idCompetencias.indexOf(c.id) >= 0);

    if (data.id > 0) Atualizar(data);
    else {
      data.IdContatoPerfilPlanejamento = id;
      ppcCompetenciasActions
        .create(data)
        .then((result: IResultadoRealizacaoCompetencia) => {
          try {
            if (result.id != undefined) {
              setResultadoRealizacaoCompetencias(perfilRealizacoes.concat(result));
              atualizarFormulario(empty);
              toast.success('Cadastro realizado com sucesso');

              setResultadoRealizacaoCompetenciaTemp(empty);
              setLoadingSave(false);
            }
          } catch (error) {
            console.error(error);
            setLoadingSave(false);
          }
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
          setLoadingSave(false);
        });
    }

    setLoadingSave(false);
  };

  useEffect(() => {
    competenciasActions
      .list()
      .then((resp: ICompetencia[]) => setCompetencias(resp))
      .catch((err: any) => toast.warn(err));

    ppcCompetenciasActions
      .list(false, `?IdContatoPerfilPlanejamento=${id}`)
      .then((resp: IResultadoRealizacaoCompetencia[]) => {
        setResultadoRealizacaoCompetencias(resp);
      })
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    if (result.tbCompetencias != undefined)
      result.idCompetencias = result.tbCompetencias?.map((c: any) => c.id);
    setResultadoRealizacaoCompetenciaTemp(result);
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    // setResultadoRealizacaoCompetenciaTemp(obj)
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    // setResultadoRealizacaoCompetenciaTemp({});
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {!loadingForm ? (
        <Grid item xs={12} md={12} sm={12}>
          <FormControl required fullWidth variant="outlined">
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  problemaDesafio: e.target.value,
                });
              }}
              value={perfilRealizacoesTemp?.problemaDesafio}
              label="Problema Desafio"
              multiline
              maxRows={4}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined">
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  acao: e.target.value,
                });
              }}
              value={perfilRealizacoesTemp?.acao}
              label="Ação"
              multiline
              maxRows={4}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined">
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  resultado: e.target.value,
                });
              }}
              value={perfilRealizacoesTemp?.resultado}
              label="Resultados"
              multiline
              maxRows={4}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined">
            <FormInput
              onChange={(e) => {
                setResultadoRealizacaoCompetenciaTemp({
                  ...perfilRealizacoesTemp,
                  sintese: e.target.value,
                });
              }}
              value={perfilRealizacoesTemp?.sintese}
              label="Síntese"
              multiline
              maxRows={4}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            {competencias && (
              <MultiSelect
                opcoes={competencias as IdDescricao[]}
                setValor={(e: any) => {
                  setResultadoRealizacaoCompetenciaTemp({
                    ...perfilRealizacoesTemp,
                    idCompetencias: e,
                  });
                }}
                valor={perfilRealizacoesTemp?.idCompetencias ?? []}
                label="Competências"
              />
            )}
          </FormControl>

          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              paddingTop: '10px',
              paddingBottom: '20px',
            }}
          >
            <ButtonStato variant="contained" onClick={() => onSubmit(perfilRealizacoesTemp)}>
              {idAtualizar ? 'Salvar' : 'Adicionar'}
            </ButtonStato>
            {idAtualizar ? (
              <ButtonStato
                color="error"
                style={{ marginLeft: '25px' }}
                onClick={() => atualizarFormulario(empty)}
                variant="contained"
              >
                Cancelar
              </ButtonStato>
            ) : (
              <></>
            )}
          </div>
        </Grid>
      ) : (
        <Box sx={{ width: '100%', paddingTop: '5px' }}>{loadingSave && <LinearProgress />}</Box>
      )}

      <Grid item md={0.5}></Grid>

      <Grid item xs={12} md={12} sm={12}>
        <Box
          sx={{
            height: '55vh',
            width: '100%',
          }}
        >
          <DataGrid
            getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
            onRowClick={handleRowClick}
            rows={perfilRealizacoes ?? []}
            columns={columns}
            loading={loading}
          />
        </Box>
      </Grid>
    </>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form``;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default PerfilRealizacoes;
