import { useBaseActions } from '../base.actions';

function useComandasActions() {
  const baseActions = useBaseActions('Comandas', undefined);

  return {
    list: baseActions.list,
  };
}

export { useComandasActions };
