
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, CircularProgress, Divider, Drawer, FormControl, FormControlLabel, Icon, InputLabel, LinearProgress, MenuItem, TableCell, TextField } from '@mui/material';
import { useGraduacoesActions } from '../../../states/graduacao/graduacao.actions';
import { IGraduacao } from '../../../commons/types/crm';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import { ButtonStato, IconDrawer, SelectStato, TextFieldStato } from '../../../commons/styleds/styleds';
import { GridItemCadastroBtnSalvar } from '../../../commons/styleds/grids';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';



const FormacaoAcademicaComponent: React.FC = () => {
    const graduacoesActions = useGraduacoesActions();
    const [graduacoes, setGraducoes] = useState<IGraduacao[]>([]);

    const [listGraduacao, setListGraduacao] = useState<any[]>([
        { "id": 1, "nome": "Graduação, Pós, MBA e Especializações" },
        { "id": 2, "nome": "Outros cursos ou seminários/congressos" }
    ]);

    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [mes, setMes] = useState<string>("");
    const [ano, setAno] = useState<string>("");
    const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);

    const [graduacao, setGraduacao] = useState<IGraduacao>();
    const [selectedGraduacaos, setSelectedGraduacaos] = useState<
        IGraduacao[] | undefined
    >(undefined);


    useEffect(() => {
        graduacoesActions.list(false, `?contatoId=${auth?.id}`)
            .then((resp: any) => {
                setLoadingGet(false);
                setGraducoes(resp)
            })
            .catch((err: any) => {
                toast.warn(err)
                setLoadingGet(false);
            });

    }, []);

    const handleEditar = (graduacao: IGraduacao) => {
        setIdAtualizar(graduacao.id);
        setGraduacao(graduacao);
        setOpen(true);
    };

    const HandleSetFormcao = (result: any) => {
        setGraduacao({ ...graduacao, tipo: result.target.value });
    };

    const openDrawerGraduacao = (result: any) => {
        setGraduacao(undefined);
        setOpen(result);
    };

    const handleApagar = (id: any) => {
        graduacoesActions.delete(id).then((data) => {
            const sub = graduacoes.filter((f) => f.id !== id);
            setGraducoes(sub);
            toast.success('Item excluído');
        }).catch((erro) => {

        });
    };

    const adicionarNaLista = () => {
        if (!graduacao?.tipo) {
            toast.warn('Selecione o tipo de formação');
            return;
        }
        setLoading(true);

        let idContato = 0;
        if (Number(auth?.id) > 0)
            graduacao!.IdContato = Number(auth?.id);

        if (graduacao?.id && graduacao?.id > 0)
            graduacoesActions.update(graduacao!.id, graduacao).then((data: IGraduacao) => {
                const newList = graduacoes.filter(g => g.id !== data.id);
                setGraducoes([...newList, data]);
                openDrawerGraduacao(false);
                setLoading(false);
                toast.success('Item editado');
            });
        else
            graduacoesActions.create(graduacao!).then((data: IGraduacao) => {
                const newList = graduacoes.concat(data!);
                setGraducoes(newList);
                openDrawerGraduacao(false);
                setLoading(false);
                toast.success('Item adicionado');
            })
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: "20px 0px" }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Formação Acadêmica
                    </Typography>
                    <Button
                        style={{
                            padding: '0px',
                            height: '27px',
                            minWidth: '27px',
                            fontSize: '20px',
                            borderRadius: '50%',
                            marginTop: '10px',
                        }}
                        onClick={() => openDrawerGraduacao(true)}
                        variant='contained'
                    >
                        +
                    </Button>
                </Grid>
                <Divider orientation="horizontal" />
            </Grid>

            <Grid container xs={12} md={12}>
                <Grid container xs={12} md={12} sx={{ padding: "10px" }}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">

                            <TableHead sx={{ minWidth: 650, backgroundColor: "#0e95ba85" }}>

                                <TableRow>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Instituição</TableCell>
                                    <TableCell>Curso</TableCell>
                                    <TableCell>Mês/Ano</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "#0392ca24" }}>
                                {graduacoes.map((row: IGraduacao) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {listGraduacao.find((f) => f.id == row.tipo)?.nome}
                                        </TableCell>
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell>{row.programa}</TableCell>
                                        <TableCell>{row.mesGraduacao} / {row.anoGraduacao}</TableCell>
                                        <TableCell>
                                            <Grid container xs={12} md={12}>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => handleEditar(row)}
                                                    >
                                                        {' '}
                                                        <EditIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => handleApagar(row.id!)}
                                                    >
                                                        {' '}
                                                        <DeleteIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loadingGet &&
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
                            <LinearProgress sx={{ width: "100%" }} />
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Drawer
                style={{ minWidth: '250px' }}
                anchor={'right'}
                open={open}
                onClose={() => openDrawerGraduacao(false)}
            >
                <Grid item xs={12} md={12} style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}>

                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: "center" }}>
                        <Grid item xs={2} md={2}>
                            <IconDrawer onClick={() => openDrawerGraduacao(false)}>close</IconDrawer>
                        </Grid>
                        <Grid item xs={10} md={10}>
                            <Typography variant="h5">{'Adicionar Graduação na Lista'}</Typography>
                        </Grid>
                    </Grid>

                    <FormControl required fullWidth variant='outlined' sx={{ marginTop: "20px" }}>
                        <InputLabel id='demo-simple-select-label'>
                            Tipo Formação
                        </InputLabel>
                        <SelectStato value={graduacao?.tipo} onChange={HandleSetFormcao} required label='Tipo Formação'>
                            {listGraduacao &&
                                listGraduacao!.map(
                                    (graduacaoMap, index: number) => (
                                        <MenuItem value={graduacaoMap.id} key={index}>
                                            {graduacaoMap.nome}
                                        </MenuItem>
                                    )
                                )}
                        </SelectStato>
                    </FormControl>

                    <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                        <TextFieldStato
                            id={"GraduacaoInput"}
                            type="text"
                            label="Curso"
                            value={graduacao?.nome}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGraduacao({ ...graduacao, nome: event.target.value });
                            }}
                        />
                    </FormControl>

                    <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                        <TextFieldStato
                            id={"GraduacaoInput"}
                            type="text"
                            label="Instituição"
                            value={graduacao?.programa}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGraduacao({ ...graduacao, programa: event.target.value });
                            }}
                        />
                    </FormControl>

                    <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setGraduacao({ ...graduacao, graduado: event.target.checked });
                                    }}
                                    checked={graduacao?.graduado}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label='Cursando'
                        />
                    </FormControl>

                    <InputLabel sx={{ 'marginTop': '20px' }}>Graduação em:</InputLabel>
                    <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                        <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '10px', 'marginRight': '10px' }}>
                                <TextFieldStato
                                    id={"GraduacaoInput"}
                                    type="number"
                                    label="Mês"
                                    value={graduacao?.mesGraduacao}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setGraduacao({
                                            ...graduacao,
                                            mesGraduacao: event.target.value,
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '10px' }}>
                                <TextFieldStato
                                    id={"GraduacaoInput"}
                                    type="number"
                                    label="Ano"
                                    value={graduacao?.anoGraduacao}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setGraduacao({
                                            ...graduacao,
                                            anoGraduacao: Number(event.target.value)
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>


                    <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
                        <TextFieldStato
                            sx={{ height: 90 }}
                            id={"GraduacaoInput"}
                            type="text"
                            label="Descrição do Curso ou Certificações"
                            value={graduacao?.descricao}
                            multiline
                            maxRows={4}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGraduacao({ ...graduacao, descricao: event.target.value });
                            }}
                        />
                    </FormControl>

                    <GridItemCadastroBtnSalvar item xs={11} md={11} sx={{
                        position: "fixed",
                        right: "0px",
                        bottom: "0px"
                    }}>
                        <ButtonStato variant="contained" onClick={adicionarNaLista}>Adicionar</ButtonStato>
                    </GridItemCadastroBtnSalvar>
                </Grid>
            </Drawer >
        </>
    );
};

export default FormacaoAcademicaComponent;
